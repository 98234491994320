module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ea5d8a9c0adb4fc08c417288fdb852f4@o419256.ingest.sentry.io/5330683","environment":"production","enabled":true,"tags":{"git_commit":"07157e0\n"},"release":"07157e009d5b1cca89bcc7a7653f3e944891b3f8\n"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mono FM","short_name":"Mono FM","start_url":"/","background_color":"#f7f0eb","theme_color":"#242629","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171619972-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
